// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


// I think this is the same thing as new webpack.ProvidePlugin
// I think it just makes jquery available in the packs BUT not in the views

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import jquery from 'jquery';
window.jQuery = window.$ = window.jquery = jquery;

import Rails from "@rails/ujs";
Rails.start();

// require("turbolinks").start()
require("@rails/activestorage").start();
require("channels");
require("jquery-ui");

import "@fortawesome/fontawesome-free/css/all"
// removed 10/20/21 import "@fortawesome/fontawesome-free/js/all";
//require("bootstrap-treeview.js");

// the simple editor
require("trix");
require("@rails/actiontext");

// import "trix/dist/trix.css";
// import "trix";


// for field validation
require("parsleyjs");

// 12.30.2021 require("src/vendors/animated-headline");
require("src/vendors/bootstrap-treeview");
// 12.30.2021 require("src/vendors/chart.min");
// 12.30.2021 require("src/vendors/clipboard.min");
// 12.30.2021 require("src/vendors/countdown");
// 12.30.2021 require("src/vendors/easy.responsive.tabs");
require("src/vendors/isotope.pkgd.min");
// 12.30.2021 require("src/vendors/nav-menu");
// 12.30.2021 require("src/vendors/owl.carousel");
// 12.30.2021 require("src/vendors/prism");

// require("src/vendors/rev_slider/jquery.themepunch.tools.min");  
// require("src/vendors/rev_slider/jquery.themepunch.revolution.min");  
// require("src/vendors/rev_slider/extensions/revolution.extension.actions.min");  
// require("src/vendors/rev_slider/extensions/revolution.extension.carousel.min");  
// require("src/vendors/rev_slider/extensions/revolution.extension.kenburn.min");  
// require("src/vendors/rev_slider/extensions/revolution.extension.layeranimation.min");  
// require("src/vendors/rev_slider/extensions/revolution.extension.migration.min");  
// require("src/vendors/rev_slider/extensions/revolution.extension.navigation.min");  
// require("src/vendors/rev_slider/extensions/revolution.extension.parallax.min");  
// require("src/vendors/rev_slider/extensions/revolution.extension.slideanims.min");  
// require("src/vendors/rev_slider/extensions/revolution.extension.video.min");  
require("src/vendors/scroll-it.min");
require("src/vendors/setup");
require("src/vendors/snackbar");
require("src/vendors/waypoints.min");
require("src/vendors/xzoom");

//require("src/plugins/jquery.hammer.min");
require("src/plugins/jquery.counterup.min");
require("src/plugins/jquery.magnific-popup.min");
require("src/plugins/jquery.stellar.min");
require("src/plugins/jquery.zoomable");
require("src/plugins/jquery.rwdImageMaps")


// custom helpers for courseware
require("src/components/courseware");
require("src/components/sidenav");
require("src/components/how_to_javascript");
require("src/components/case_question_feedback");

require("src/base/main.js");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@nathanvda/cocoon")

import flatpickr from "flatpickr";
require("flatpickr/dist/flatpickr.min.css");

$(document).ready(function() {
  flatpickr(".datepicker", {});
});

require("popper.js");
require("bootstrap");

import 'stylesheets/application';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import 'jquery-ui/ui/widgets/sortable';  // If you are using sortable
import 'jquery-ui/ui/widgets/resizable';  // If you are using resizable
import 'jquery-ui/ui/widgets/accordion';  // If you are using accordion

