$(function() {
  $('[data-toggle="tooltip"]').tooltip({
  });
  $('[data-toggle="popover"]').popover({
  });
});


window.load_title = function()
{
  if (!selectedNode) 
  {
    selectedNode = $('#course-tree').treeview('getNode', 0);
  }
    
  var strTitle = selectedNode.text

  var str = "<h2 style='color: #84B8B9'>" + strTitle + "</h2><hr>"

  $('#inner-right').html(str);
}


window.load_flysheet = function()
{
  if (!selectedNode) 
  {
    selectedNode = $('#course-tree').treeview('getNode', 0);
  }
    
  var strTitle = selectedNode.text




  var str = "<h1>" + strTitle + "<style>.img-centered {  display: block; padding-top: 100px;  margin-left: auto;  margin-right: auto;  width: 40%;}</style></h1><hr><div><img class='img-centered' src='https://el-cdn.s3.amazonaws.com/Evidentia_Learning/assets/images/logos/GREY/Logo%20Circle/EvidentiaLearning%20logo%20circle%20grey45.png'></div><br><br><h6 style='text-align: center; opacity: 0.45;'>This page left blank intentionally.</h6>"

  $('#inner-right').html(str);
}

window.load_contents_id = function(slug_id, slug)
{
  $.ajax(
  {
    url: "/courses/get_contents",
    data: {slug: slug}
  }).done(
    function(html_from_server) 
    {
      $(slug_id).html(html_from_server);
    }
  );
};

window.load_contents_from_file = function(slug)
{
  $.ajax(
  {
    url: "/courses/get_contents_from_file",
    data: {slug: slug}
  }).done(
    function(html_from_server) 
    {
      var html_page = "<div id='mainContent' style='display:none;'>";
      html_page += html_from_server;
      html_page += "</div>";
      html_page += "<script>document.getElementById('mainContent').scrollIntoView(); show_mainContent();</script>"; 

      $('#inner-right').html(html_page);

      $('img[data-zoomable]').addClass('img-zoomable').click(function(){
        var src = $(this).attr('src');
        var modal;
        function removeModal(){ modal.remove(); $('body').off('keyup.modal-close'); }
        modal = $('<div>').css({
            background: 'RGBA(0,0,0,1.0) url('+src+') no-repeat center',
            backgroundSize: 'contain',
            width:'100%', height:'100%',
            position:'fixed',
            zIndex:'10000',
            top:'0', left:'0',
            cursor: 'zoom-out'
        }).click(function(){
            removeModal();
        }).appendTo('body');
        //handling ESC
        $('body').on('keyup.modal-close', function(e){
          if(e.key==='Escape'){ removeModal(); } 
        });
      });      
    }
  );
};

window.load_contents_from_partial = function(slug)
{
  $.ajax(
  {
    url: "/courses/get_contents_from_partial",
    data: {slug: slug}
  }).done(
    function(html_from_server) 
    {
      var html_page = "<div id='mainContent' style='display:none;'>";
      html_page += html_from_server;
      html_page += "</div>";
      html_page += "<script>document.getElementById('mainContent').scrollIntoView(); show_mainContent();</script>"; 

      $('#inner-right').html(html_page);

      $('img[data-zoomable]').addClass('img-zoomable').click(function(){
        var src = $(this).attr('src');
        var modal;
        function removeModal(){ modal.remove(); $('body').off('keyup.modal-close'); }
        modal = $('<div>').css({
            background: 'RGBA(0,0,0,1.0) url('+src+') no-repeat center',
            backgroundSize: 'contain',
            width:'100%', height:'100%',
            position:'fixed',
            zIndex:'10000',
            top:'0', left:'0',
            cursor: 'zoom-out'
        }).click(function(){
            removeModal();
        }).appendTo('body');
        //handling ESC
        $('body').on('keyup.modal-close', function(e){
          if(e.key==='Escape'){ removeModal(); } 
        });
      });      
    }
  );
};



window.load_contents = function(slug)
{
  $.ajax(
  {
    url: "/courses/get_contents",
    data: {slug: slug}
  }).done(
    function(html_from_server) 
    {
      var html_page = "<div id='mainContent' style='display:none;'>";
      html_page += html_from_server;
      html_page += "</div>";
      html_page += "<script>document.getElementById('mainContent').scrollIntoView(); show_mainContent();</script>"; 

      $('#inner-right').html(html_page);

      $('img[data-zoomable]').addClass('img-zoomable').click(function(){
        var src = $(this).attr('src');
        var modal;
        function removeModal(){ modal.remove(); $('body').off('keyup.modal-close'); }
        modal = $('<div>').css({
            background: 'RGBA(0,0,0,1.0) url('+src+') no-repeat center',
            backgroundSize: 'contain',
            width:'100%', height:'100%',
            position:'fixed',
            zIndex:'10000',
            top:'0', left:'0',
            cursor: 'zoom-out'
        }).click(function(){
            removeModal();
        }).appendTo('body');
        //handling ESC
        $('body').on('keyup.modal-close', function(e){
          if(e.key==='Escape'){ removeModal(); } 
        });
      });      
    }
  );
};

window.load_json_contents = function(slug)
{
  $.ajax(
  {
    url: "/courses/get_json_contents",
    data: {slug: slug},
    dataType: "json",
  })
    .always(function() {
      //console.log( "complete" );
    })
    .done(
      function(json_from_server) 
      {
        //$.each( json_from_server, function( key, value ) {
        //  console.log(key);
        //  console.log(value);
        //});
        //console.log(json_from_server.body);

        var image_list = "";
        if (json_from_server != null)
        {
          var images = json_from_server.media_assets;
          //alert(images[0].fname);

          images.forEach(image => {
            myURL = "https://startin-atlas.s3.amazonaws.com/users/0/images/" + image.fname;
            image_list += "<div class='col'><img data-zoomable class='img-fluid'  style='cursor: zoom-in; height: auto;border: 1px solid #A8A8A7;' src='" + myURL + "' ></div>";
          });
        }
        //console.log(image_list);

        // removed during development
        body = json_from_server.body.replace("Anglo ","");
        body = body.replace("Hispanic ","");
        body = body.replace("Afro-American ","");
        body = body.replace("African-American ","");
        body = body.replace("Asian female","female");

        // you will need to switch the comment to get the images to appear  
        str = "<div style='margin-left: 50px;margin-right:50px;'><h2 style='color: #84B8B9'>" + json_from_server.title + "</h2><hr><p>" + json_from_server.body + "</p><hr><div class='row'>" + image_list + "</div></div>"
        // str = "<div class='container'><p>" + body + "</p><hr><p><div class='row'><div class='col'>" + image_list + "</div></div></p></div>"

        $('#inner-right').html(str); 

      $('img[data-zoomable]').addClass('img-zoomable').click(function(){
        var src = $(this).attr('src');
        var modal;
        function removeModal(){ modal.remove(); $('body').off('keyup.modal-close'); }
        modal = $('<div>').css({
            background: 'RGBA(0,0,0,1.0) url('+src+') no-repeat center',
            backgroundSize: 'contain',
            width:'100%', height:'100%',
            position:'fixed',
            zIndex:'10000',
            top:'0', left:'0',
            cursor: 'zoom-out'
        }).click(function(){
            removeModal();
        }).appendTo('body');
        //handling ESC
        $('body').on('keyup.modal-close', function(e){
          if(e.key==='Escape'){ removeModal(); } 
        });
      });      
      }
    )
    .error(
      function(json_from_server) 
      {
        $('#inner-right').html(str); 
      }
    )
    .fail(
      function(json_from_server) 
      {
        $('#inner-right').html(str); 
      }
    );
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

window.load_json_atlas_topic = function(slug,show_content_images=false)
{
  $('#inner-right').html("");
  load_title();
  $.ajax(
  {
    url: "/courses/get_json_topic",
    data: {slug: slug},
    dataType: "json",
  })
    .always(function() {
      //console.log( "complete" );
    })
    .done(
      async function(json_from_server) 
      {
        var str = ""
        $.each( json_from_server, function( key, value ) {
          //console.log(key);
          //console.log(value);

          var mycase = value;
          console.log(mycase);

          var target = mycase._id.$oid

          var body = mycase.body.replace("Anglo ","");
          body = body.replace("Hispanic ","");
          body = body.replace("Afro-American ","");
          body = body.replace("African-American ","");
          body = body.replace("Asian female","female");

          str = "<div style='margin-left: 50px;margin-right:50px;'><h6>" + body + "</h6><div class='row' id='" + target + "'></div></div><hr>"
          $('#inner-right').append(str); 
          insert_html_image_list(target,mycase._id.$oid,show_content_images);
        });

        await sleep(5000);

        /////////////
        $('img[data-zoomable]').addClass('img-zoomable').click(function(){
          var src = $(this).attr('src');
          var modal;
          function removeModal(){ modal.remove(); $('body').off('keyup.modal-close'); }
          modal = $('<div>').css({
              background: 'RGBA(0,0,0,1.0) url('+src+') no-repeat center',
              backgroundSize: 'contain',
              width:'100%', height:'100%',
              position:'fixed',
              zIndex:'10000',
              top:'0', left:'0',
              cursor: 'zoom-out'
          }).click(function(){
              removeModal();
          }).appendTo('body');
          //handling ESC
          $('body').on('keyup.modal-close', function(e){
            if(e.key==='Escape'){ removeModal(); } 
          });
        });     



      }
    )
    .error(
      function(json_from_server) 
      {
        $('#inner-right').html(str); 
      }
    )
    .fail(
      function(json_from_server) 
      {
        $('#inner-right').html(str); 
      }
    );
};


window.insert_html_image_list = function(target,caseid,show_content_images=true)
{
  
  var image_list = ""
  $.ajax(
  {
    url: "/courses/get_json_case",
    data: {caseid: caseid},
    dataType: "json",
  })
    .always(function() {
      //console.log( "get_html_image_list called" );
    })
    .done(
      function(json_from_server) 
      {
        if (json_from_server != null)
        {
          var image_list = "";
          var images = json_from_server.media_assets;

          images.forEach(image => {
            // console.log(image);
            var myURL = "";

            image_list += "<div class='col-lg-4'>"
            if (image.type == "image") {
              if (show_content_images) {

                myURL = "https://s3.us-east-1.amazonaws.com/EvidentiaLearning/com/evidentialearning/content/CSA/users/0/images/" + image.fname;
              }
              else {
                myURL = "https://s3.amazonaws.com/EvidentiaLearning/com/evidentialearning/assets/images/logos/GREY/Logo%20Circle/EvidentiaLearning%20logo%20circle%20grey60.png"
              }
              image_list += "<img data-zoomable class='img-fluid'  style='cursor: zoom-in; height: auto;border: 1px solid #A8A8A7; margin-bottom: 20px;' src='" + myURL + "' >";
            } else if (image.type == "video") {
              if (show_content_images) {
                myURL = "https://s3.us-east-1.amazonaws.com/EvidentiaLearning/com/evidentialearning/content/CSA/video/" + image.video_name + ".mp4";
              }
              else {
                myURL = "https://s3.amazonaws.com/EvidentiaLearning/com/evidentialearning/assets/images/logos/GREY/Logo%20Circle/EvidentiaLearning%20logo%20circle%20grey60.png"
              }
              image_list += "<video style='width:100%; height: auto;' controls>"
              image_list += "<source src='" + myURL + "' type='video/mp4'>";
              image_list += "</video>";
              //console.log(myURL);
            }
            image_list += "</div>";
            document.getElementById(target).innerHTML = image_list;
          });
        }
      }
    )
    .error(
      function(json_from_server) 
      {
        //console.log( "get_html_image_list - error");
      }
    )
    .fail(
      function(json_from_server) 
      {
        //console.log( "get_html_image_list - fail");
      }
    );
           

};

window.load_json_case = function(caseid)
{
  $.ajax(
  {
    url: "/courses/get_json_case",
    data: {caseid: caseid},
    dataType: "json",
  })
    .always(function() {
      //console.log( "complete" );
    })
    .done(
      function(json_from_server) 
      {
        var image_list = "";
        if (json_from_server != null)
        {
          images = json_from_server.media_assets;
          //alert(images[0].fname);

          images.forEach(image => {
            // https://s3.us-east-1.amazonaws.com/EvidentiaLearning/com/evidentialearning/content/CSA/users/0/image_thumbnails/32400034.jpg
            myURL = "https://s3.us-east-1.amazonaws.com/EvidentiaLearning/com/evidentialearning/content/CSA/users/0/images/" + image.fname;
            image_list += "<div class='col'><img data-zoomable class='img-fluid'  style='cursor: zoom-in; height: auto;border: 1px solid #A8A8A7;' src='" + myURL + "' ></div>";
          });
        }
        //console.log(image_list);

        // removed during development
        body = json_from_server.body.replace("Anglo ","")
        body = body.replace("Hispanic ","")
        body = body.replace("Afro-American ","")      
        body = body.replace("African-American ","")
        body = body.replace("Asian female","female")

        // you will need to switch the comment to get the images to appear  
        str = "<div style='margin-left: 50px;margin-right:50px;'><h2 style='color: #84B8B9'>" + json_from_server.title + "</h2><hr><p>" + json_from_server.body + "</p><hr><div class='row'>" + image_list + "</div></div>"
        // str = "<div class='container'><p>" + body + "</p><hr><p><div class='row'><div class='col'>" + image_list + "</div></div></p></div>"

        $('#inner-right').html(str); 

      $('img[data-zoomable]').addClass('img-zoomable').click(function(){
        var src = $(this).attr('src');
        var modal;
        function removeModal(){ modal.remove(); $('body').off('keyup.modal-close'); }
        modal = $('<div>').css({
            background: 'RGBA(0,0,0,1.0) url('+src+') no-repeat center',
            backgroundSize: 'contain',
            width:'100%', height:'100%',
            position:'fixed',
            zIndex:'10000',
            top:'0', left:'0',
            cursor: 'zoom-out'
        }).click(function(){
            removeModal();
        }).appendTo('body');
        //handling ESC
        $('body').on('keyup.modal-close', function(e){
          if(e.key==='Escape'){ removeModal(); } 
        });
      });      
      }
    )
    .error(
      function(json_from_server) 
      {
        $('#inner-right').html(str); 
      }
    )
    .fail(
      function(json_from_server) 
      {
        $('#inner-right').html(str); 
      }
    );
};


window.show_mainContent = function()
{
   $("#mainContent").fadeIn(500);
}

